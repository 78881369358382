import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/main_menu";
// import Services from "../../Services/Services";

export default class MainMenu extends Component {
  id = "slider";
  group = "slider";

  menus = [
    {
      _values: {
        "sub-menu": [
          {
            slug: "sofa",
            title: "Sofas",
            type: "living-area",
            url: "/products/type/sofa",
            position: "1",
          },
          {
            type: "living-area",
            title: "Armchairs",
            slug: "armchair",
            url: "/products/type/armchair",
            position: "2",
          },
          {
            type: "dinning-area",
            url: "/products/type/table",
            position: "1",
            slug: "table",
            title: "Tables",
          },
          {
            type: "dinning-area",
            position: "2",
            url: "/products/type/chair",
            title: "Chairs",
            slug: "chair",
          },
          {
            type: "living-area",
            slug: "stool",
            title: "Stool",
            url: "/products/type/stool",
            position: "8",
          },
          {
            type: "living-area",
            url: "/products/type/daybed",
            position: "7",
            slug: "daybed",
            title: "Daybeds",
          },
          {
            type: "living-area",
            title: "Bookcases",
            url: "/products/type/bookcase",
            position: "5",
            slug: "bookcase",
          },
          {
            type: "living-area",
            title: "TV Units",
            url: "/products/type/tv-unit",
            position: "4",
            slug: "tv-unit",
          },
          {
            type: "living-area",
            title: "Coffee Tables",
            url: "/products/type/coffee-table",
            slug: "coffee-table",
            position: "3",
          },
          {
            position: "4.5",
            slug: "wall-unit",
            title: "Wall Units",
            url: "/products/type/wall-unit",
            type: "living-area",
          },
          {
            type: "bedroom",
            url: "/products/type/bed",
            position: "1",
            slug: "bed",
            title: "Beds",
          },
          {
            type: "bedroom",
            url: "/products/type/dressers-bedside-table",
            position: "2",
            slug: "dressers-bedside-table",
            title: "Dressers & Bedside Tables",
          },
          {
            type: "bedroom",
            url: "/products/type/sofa-bed",
            position: "3",
            slug: "sofa-bed",
            title: "Sofa Beds",
          },
          {
            type: "bedroom",
            url: "/products/type/dressing-table",
            position: "4",
            slug: "dressing-table",
            title: "Dressing Tables",
          },
          {
            type: "complement",
            url: "/products/type/mirror",
            position: "1",
            slug: "mirror",
            title: "Mirrors",
          },
          {
            type: "complement",
            url: "/products/type/console",
            position: "2",
            slug: "console",
            title: "Console",
          },
          {
            type: "complement",
            url: "/products/type/desk",
            position: "3",
            slug: "desk",
            title: "Desks",
          },
          {
            type: "complement",
            url: "/products/type/rug",
            position: "4",
            slug: "rug",
            title: "Rugs",
          },
          {
            type: "complement",
            url: "/products/type/hanger",
            position: "5",
            slug: "hanger",
            title: "Hanger",
          },
          {
            type: "living-area",
            slug: "side-table",
            title: "Side Tables",
            url: "/products/type/side-table",
            position: "3.5",
          },
          {
            slug: "sideboard",
            url: "/products/type/sideboard",
            title: "Sideboards",
            type: "dinning-area",
            position: "3",
          },
          {
            type: "complement",
            url: "/products/type/trolley-bar",
            slug: "trolley-bar",
            title: "Trolley Bars",
            position: "6",
          },
          {
            slug: "bar-stool",
            title: "Bar Stools",
            url: "/products/type/bar-stool",
            type: "complement",
            position: "7",
          },
          {
            type: "complement",
            slug: "accessories",
            title: "Accessories",
            url: "/products/type/accessories",
            position: "8",
          },
          {
            url: "/products/type/bench",
            title: "Bench",
            slug: "bench",
            type: "complement",
            position: "7.5",
          },
        ],
        visible: true,
        public: true,
        active: true,
        slug: "furniture",
        name: "Furniture",
        position: 1,
        url: "/catalogue/furniture",
        menu_template: "furniture",
        menu_types: [
          {
            slug: "living-area",
            link: "/catalogue/furniture/living-area",
          },
          {
            slug: "dinning-area",
            link: "/catalogue/furniture/dinning-area",
          },
          {
            link: "/catalogue/furniture/bedroom",
            slug: "bedroom",
          },
          {
            link: "/catalogue/furniture/complement",
            slug: "complement",
          },
          {
            link: "/products/type/wardrobe",
            slug: "wardrobe",
          },
          // {
          //   link: "/products/type/kids-room",
          //   slug: "kids-room",
          // },
        ],
        color: "rgb(195, 170, 166,1)",
        icon: "fa-light fa-couch",
      },
      _id: "63984e9b233267214e7f73c7",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        slug: "lighting",
        name: "Lighting",
        menu_template: "lighting",
        position: 2,
        url: "/catalogue/lighting",
        menu_types: [
          {
            slug: "pendant-lamp",
            link: "/products/type/pendant-lamp",
          },
          {
            slug: "ceiling-lamp",
            link: "/products/type/ceiling-lamp",
          },
          {
            slug: "wall-lamp",
            link: "/products/type/wall-lamp",
          },
          {
            link: "/products/type/floor-lamp",
            slug: "floor-lamp",
          },
          {
            link: "/products/type/table-lamp",
            slug: "table-lamp",
          },
          {
            link: "/products/type/outdoor-lamp",
            slug: "outdoor-lamp",
          },
        ],
        color: "rgb(165, 189, 190,1)",
        icon: "fa-light fa-light-ceiling",
      },
      _id: "63984f320e0a54250453ff7e",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        slug: "outdoor",
        name: "Outdoor",
        menu_template: "outdoor",
        url: "/catalogue/outdoor",
        position: 3,
        menu_types: [
          {
            link: "/products/type/outdoor-sofa",
            slug: "outdoor-sofa",
          },
          {
            link: "/products/type/outdoor-table",
            slug: "outdoor-table",
          },
          {
            link: "/products/type/outdoor-chair",
            slug: "outdoor-chair",
          },
          {
            link: "/products/type/outdoor-armchair",
            slug: "outdoor-armchair",
          },
          {
            link: "/products/type/outdoor-relax",
            slug: "outdoor-relax",
          },
          {
            link: "/products/type/outdoor-sunbed",
            slug: "outdoor-sunbed",
          },
          {
            link: "/products/type/outdoor-bar-stool",
            slug: "outdoor-bar-stool",
          },
          {
            link: "/products/type/outdoor-hanging-chair",
            slug: "outdoor-hanging-chair",
          },
          {
            link: "/products/type/outdoor-accessories-decor",
            slug: "outdoor-accessories-decor",
          },
        ],
        color: "rgb(166, 185, 165,1)",
        icon: "fa-light fa-sun-bright",
      },
      _id: "63984f7e8b212b66fc3475a6",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        slug: "wardrobe",
        name: "Wardrobes",
        menu_template: "wardrobes",
        position: 4,
        url: "/products/type/wardrobe",
        menu_types: [],
        color: "rgb(195, 193, 186,1)",
        icon: "fa-light fa-clothes-hanger",
      },
      _id: "63984fceaa105f652c3889c7",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        slug: "kitchen",
        name: "Kitchen",
        menu_template: "kitchen",
        url: "/products/type/kitchen",
        position: 5,
        color: "rgb(183, 185, 210,1)",
        menu_types: [],
        icon: "fa-light fa-hat-chef",
      },
      _id: "63984ffe1628f0745d18c167",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        slug: "brands",
        name: "Brands",
        menu_template: "brands",
        url: "/all-brands",
        position: 7,
        color: "rgb(228, 211, 208,1)",
        menu_types: [],
        icon: "fa-light fa-star",
      },
      _id: "6398503141dfb5066253de72",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        slug: "showrooms",
        name: "Showrooms",
        menu_template: "showrooms",
        url: "/stores",
        position: 8,
        color: "rgb(188, 228, 217,1)",
        menu_types: [],
        icon: "fa-light fa-location-dot",
      },
      _id: "639850547278815287249f45",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        menu_types: [],
        icon: "fa-light fa-pen-ruler",
        position: 10,
        slug: "designers",
        name: "Designers",
        menu_template: "designers",
        url: "/designers",
        color: "rgba(191,157,132,255)",
      },
      _id: "671d43d2acb02f7c475a2b84",
    },
    {
      _values: {
        "sub-menu": [],
        visible: true,
        public: true,
        active: true,
        menu_types: [],
        icon: "fa-solid fa-circle",
        position: 10,
        slug: "event",
        name: "Event",
        menu_template: "event",
        url: "/products/brand/desiree",
        color: "rgb(255, 99, 99,1)",
      },
      _id: "671d43d2acb02f7c475a2b87",
    },
  ];

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getMenu();
  }

  getMenu() {
    this.setData({
      "default.mainMenu": this.fixMainMenu(this.menus),
    });
    // Services.get("hook").then(([service]) => {
    //   service.exec("main-menu").then((response) => {
    //     const menus = response.getData() || {};
    //     this.setData({
    //       "default.mainMenu": this.fixMainMenu(menus),
    //     });
    //   });
    // });
  }

  fixMainMenu(menu) {
    menu = menu
      .map((menu) => {
        const { _values = {} } = menu;
        const subMenu = _values["sub-menu"] || [];
        const newSubMenu = {};

        subMenu.forEach((subMenu) => {
          const { type } = subMenu;

          if (!newSubMenu[type]) {
            newSubMenu[type] = [];
          }

          newSubMenu[type].push(subMenu);
        });

        for (var i in newSubMenu) {
          newSubMenu[i] = newSubMenu[i].sort((a, b) => {
            const posA = a.position * 1;
            const posB = b.position * 1;

            return posA - posB;
          });
        }

        _values["subMenu"] = newSubMenu;

        const menu_types = _values["menu_types"] || [];
        menu_types.forEach((type) => {
          const { slug } = type;
          type["has_menu"] = Object.hasOwn(newSubMenu, slug);
        });

        return _values;
      })
      .sort((menu1, menu2) => {
        const pos1 = menu1.position || 0;
        const pos2 = menu2.position || 0;
        return pos1 - pos2;
      });

    return menu;
  }
}
